<template>
  <div dir="ltr" class="wrap-big privacy" v-scroll="handleScroll">
    <div class="mb-16">
      <div class="content primary--text pt-7 pb-5 mx-auto">
        <div class="h3">Privacy Policy</div>
        <div class="sidebar">
          <div>
            <div class="link sidebar-item" v-for="(item, i) in menuList" :key="item" @click="scrollToItem(i)">
              <div class="arrow"><img src="@/assets/icon/right.svg" alt="arrow-right" /></div>
              <div class="primary--text">{{ item }}</div>
            </div>
          </div>
          <v-card :max-width="width <= 1280 ? '310' : '318'" outlined color="accent" class="pa-lg-6 pa-4 pb-lg-8 pb-6 my-5 me-5 app">
            <div class="primary--text text-h5 font-weight-black">{{ $t('apps.title') }}</div>
            <div class="primary--text mt-1">{{ $t('apps.desc') }}</div>
            <div class="mt-8 d-flex justify-space-between flex-wrap">
              <a href="https://apps.apple.com/us/app/snobar/id1613143471" target="_blank" class="d-block" style="height: 40px">
                <img
                  height="40"
                  width="120"
                  :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
                  alt="apple"
                  :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.snobar.client"
                target="_blank"
                class="d-block"
                style="height: 40px"
              >
                <img
                  height="40"
                  width="120"
                  :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
                  alt="google"
                  :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
                />
              </a>
            </div>
          </v-card>
        </div>
        <div class="mt-5">
          This privacy notice for SNOBAR ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share
          ("process") your information when you use our services ("Services"), such as when you:
        </div>
        <div class="d-flex mt-3">
          <div class="square"></div>
          <div>
            Download and use our application(s), such as our mobile application — SNOBAR, or any other application of ours that links to
            this privacy notice
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Engage with us in other related ways — including any sales, marketing, or events.</div>
        </div>
        <div class="f18 mt-4">Questions or concerns?</div>
        <div class="mt-1">
          Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any questions or concerns, please contact us at
          <a href="mailto:jamal@snobar.app">jamal@snobar.app</a>.
        </div>
        <div class="f18 mt-4">Summary of Key Points</div>
        <div class="mt-1">
          This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking
          the link following each key point or by using our table of contents below to find the section you are looking for. You can also
          click here to go directly to our table of contents.
        </div>
        <div class="f18 mt-4">What personal information do we process?</div>
        <div class="mt-1">
          When you visit, use, or navigate our Services, we may process personal information depending on how you interact with SNOBAR and
          the Services, the choices you make, and the products and features you use. Click
          <span class="blue--text text-decoration-underline link" @click="scrollToItem(0)">here</span> to learn more.
        </div>
        <div class="f18 mt-4">Do we process any sensitive personal information?</div>
        <div class="mt-1">We do not process sensitive personal information.</div>
        <div class="f18 mt-4">Do you receive any information from third parties?</div>
        <div class="mt-1">We do not receive any information from third parties.</div>
        <div class="f18 mt-4">How do you process my information?</div>
        <div class="mt-1">
          We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your
          information only when we have a valid legal reason to do so. Click
          <span class="blue--text text-decoration-underline link" @click="scrollToItem(1)">here</span> to learn more.
        </div>
        <div class="f18 mt-4">In what situations and with which parties do we share personal information?</div>
        <div class="mt-1">
          We may share information in specific situations and with specific third parties. Click
          <span class="blue--text text-decoration-underline link" @click="scrollToItem(3)">here</span> to learn more.
        </div>
        <div class="f18 mt-4">What are your rights?</div>
        <div class="mt-1">
          Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your
          personal information. Click here to learn more.
        </div>
        <div class="f18 mt-4">How do I exercise my rights?</div>
        <div class="mt-1">
          The easiest way to exercise your rights is by filling out our data subject request form available here: , or by contacting us. We
          will consider and act upon any request in accordance with applicable data protection laws.
        </div>
      </div>
      <v-card id="0" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center" max-width="702" @click="openSection(0)">
        <div class="arrow">
          <img :class="section.some((i) => i == 0) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">What information we collect?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 0)">
        <div class="f18">Personal information you disclose to us</div>
        <div class="mt-1">
          In Short: We collect personal information that you provide to us. We collect personal information that you voluntarily provide to
          us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you contact us.
        </div>
        <div class="f18 mt-4">Personal Information Provided by You</div>
        <div class="mt-1 mb-3">
          The personal information that we collect depends on the context of your interactions with us and the Services, the choices you
          make, and the products and features you use. The personal information we collect may include the following:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>names</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>phone numbers</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>email addresses</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>mailing addresses</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>usernames</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>passwords</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>contact preferences</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>contact or authentication data</div>
        </div>
        <div class="f18 mt-4">Sensitive Information</div>
        <div class="mt-1">We do not process sensitive information.</div>
        <div class="f18 mt-4">Social Media Login Data</div>
        <div class="mt-1">
          We may provide you with the option to register with us using your existing social media account details, like your Facebook,
          Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the
          section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
        </div>
        <div class="f18 mt-4">Application Data</div>
        <div class="mt-1">
          If you use our application(s), we also may collect the following information if you choose to provide us with access or
          permission:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Geolocation Information. We may request access or permission to track location-based information from your mobile device, either
            continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change
            our access or permissions, you may do so in your device's settings.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile
            device's camera, contacts, microphone, sms messages, social media accounts, and other features. If you wish to change our access
            or permissions, you may do so in your device's settings.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer),
            operating system, version information and system configuration information, device and application identification numbers,
            browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or
            proxy server). If you are using our application(s), we may also collect information about the phone network associated with your
            mobile device, your mobile device's operating system or platform, the type of mobile device you use, your mobile device's unique
            device ID, and information about the features of our application(s) you accessed.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Push Notifications. We may request to send you push notifications regarding your account or certain features of the
            application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's
            settings.
          </div>
        </div>
        <div class="mt-3">
          This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for
          our internal analytics and reporting purposes.
        </div>
        <div class="mt-3">
          All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to
          such personal information.
        </div>
        <div class="f18 mt-4">Information automatically collected</div>
        <div class="mt-1">
          We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your
          specific identity (like your name or contact information) but may include device and usage information, such as your IP address,
          browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location,
          information about how and when you use our Services, and other technical information. This information is primarily needed to
          maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
        </div>
        <div class="my-3">The information we collect includes:</div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with
            us, this log data may include your IP address, device information, browser type, and settings and information about your
            activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other
            actions you take such as which features you use), device event information (such as system activity, error reports (sometimes
            called "crash dumps"), and hardware settings).
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access
            the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server),
            device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile
            carrier, operating system, and system configuration information.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Location Data. We collect location data such as information about your devices location, which can be either precise or
            imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For
            example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP
            address). You can opt out of allowing us to collect this information either by refusing access to the information or by
            disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of
            the Services.
          </div>
        </div>
      </div>
      <v-card id="1" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(1)">
        <div class="arrow">
          <img :class="section.some((i) => i == 1) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">How do we process your information?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 1)">
        <div class="mt-1">
          In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and
          fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
        </div>
        <div class="my-3 f18">
          We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in working order.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our
            Services.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            To send you marketing and promotional communications. We may process the personal information you send to us for our marketing
            purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For
            more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including
            fraud monitoring and prevention.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            To identify usage trends. We may process information about how you use our Services to better understand how they are being used
            so we can improve them.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            To save or protect an individual's vital interest. We may process your information when necessary to save or protect an
            individual's vital interest, such as to prevent harm.
          </div>
        </div>
      </div>
      <v-card id="2" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(2)">
        <div class="arrow">
          <img :class="section.some((i) => i == 2) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">What legal bases do we rely on to process your Personal Information?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 2)">
        <div class="mt-1">
          In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal
          basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or
          fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
        </div>
        <div class="f18 my-3 text-decoration-underline">if you are located in the EU or UK, this section applies to you.</div>
        <div class="mb-3">
          The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to
          process your personal information. As such, we may rely on the following legal bases to process your personal information:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            <b>Consent.</b> We may process your information if you have given us permission (i.e., consent) to use your personal information
            for a specific purpose. You can withdraw your consent at any time. Click here to learn more.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            <b>Legitimate Interests.</b> We may process your information when we believe it is reasonably necessary to achieve our
            legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For
            example, we may process your personal information for some of the purposes described in order to:
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Send users information about special offers and discounts on our products and services</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Analyze how our services are used so we can improve them to engage and retain users</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Diagnose problems and/or prevent fraudulent activities</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Understand how our users use our products and services so we can improve user experience</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations,
            such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your
            information as evidence in litigation in which we are involved.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital
            interests of a third party, such as situations involving potential threats to the safety of any person.
          </div>
        </div>
        <div class="f18 my-3 text-decoration-underline">If you are located in Canada, this section applies to you.</div>
        <div>
          We may process your information if you have given us specific permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your
          consent at any time. Click here to learn more.
        </div>
        <div class="mt-1 mb-3">
          In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent,
          including, for example:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>For investigations and fraud detection and prevention</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>For business transactions provided certain conditions are met</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>For identifying injured, ill, or deceased persons and communicating with next of kin</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the
            information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention
            of the laws of Canada or a province
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of
            records
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>If the collection is solely for journalistic, artistic, or literary purposes</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>If the information is publicly available and is specified by the regulations</div>
        </div>
      </div>
      <v-card id="3" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(3)">
        <div class="arrow">
          <img :class="section.some((i) => i == 3) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">When and with whom do we share your personal information?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 3)">
        <div class="mt-1">
          In Short: We may share information in specific situations described in this section and/or with the following third parties.
        </div>
        <div class="mt-1 mb-3">We may need to share your personal information in the following situations:</div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            <b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger,
            sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            <b>Offer Wall.</b> Our application(s) may display a third-party hosted "offer wall." Such an offer wall allows third-party
            advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an
            advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as
            your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website
            belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the
            offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.
          </div>
        </div>
      </div>
      <v-card id="4" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(4)">
        <div class="arrow">
          <img :class="section.some((i) => i == 4) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">How do we handle your Social logins?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 4)">
        <div class="mt-1">
          In Short: If you choose to register or log in to our services using a social media account, we may have access to certain
          information about you.
        </div>
        <div class="mt-1">
          Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook
          or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media
          provider. The profile information we receive may vary depending on the social media provider concerned, but will often include
          your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a
          social media platform.
        </div>
        <div class="mt-1">
          We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made
          clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your
          personal information by your third-party social media provider. We recommend that you review their privacy notice to understand
          how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
        </div>
      </div>
      <v-card id="5" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(5)">
        <div class="arrow">
          <img :class="section.some((i) => i == 5) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">How long do we keep your information?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 5)">
        <div class="mt-1">
          In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless
          otherwise required by law.
        </div>
        <div class="mt-1">
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless
          a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in
          this notice will require us to keep your personal information for longer than the period of time in which users have an account
          with us.
        </div>
        <div class="mt-1">
          When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then
          we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </div>
      </div>
      <v-card id="6" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(6)">
        <div class="arrow">
          <img :class="section.some((i) => i == 6) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">What are your Privacy rights?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 6)">
        <div class="mt-1">
          In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow
          you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
        </div>
        <div class="mt-1">
          In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include
          the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you
          may also have the right to object to the processing of your personal information. You can make such a request by contacting us by
          using the contact details provided in the section
          <span class="blue--text text-decoration-underline link" @click="scrollToItem(10)">
            "How can you contact us about this Notice?"
          </span>
          below.
        </div>
        <div class="mt-1">We will consider and act upon any request in accordance with applicable data protection laws.</div>
        <div class="mt-1">
          If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority. You can find their contact details here:
          <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm" target="_blank">
            https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
          </a>
          .
        </div>
        <div class="mt-1">
          If you are located in Switzerland, the contact details for the data protection authorities are available here:
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
        </div>
        <div class="mt-1">
          <b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express
          and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw
          your consent at any time by contacting us by using the contact details provided in the section
          <span class="blue--text text-decoration-underline link" @click="scrollToItem(10)">
            "How can you contact us about this Notice?"
          </span>
          below or updating your preferences.
        </div>
        <div class="mt-1">
          However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law
          allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than
          consent.
        </div>
        <div class="f18 mt-3">Account Information</div>
        <div class="mt-1 mb-3">
          If you would at any time like to review or change the information in your account or terminate your account, you can:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Log in to your account settings and update your user account.</div>
        </div>
        <div class="mt-3">
          Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations,
          enforce our legal terms and/or comply with applicable legal requirements.
        </div>
        <div class="mt-1">
          If you have questions or comments about your privacy rights, you may email us at
          <a href="mailto:info@snobar.app">info@snobar.app</a>.
        </div>
      </div>
      <v-card id="7" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(7)">
        <div class="arrow">
          <img :class="section.some((i) => i == 7) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Controls for do-not-track features</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 7)">
        <div class="mt-1">
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you
          can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At
          this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not
          currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked
          online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in
          a revised version of this privacy notice.
        </div>
      </div>
      <v-card id="8" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(8)">
        <div class="arrow">
          <img :class="section.some((i) => i == 8) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Do California residents have specific Privacy rights?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 8)">
        <div class="mt-1">
          In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
        </div>
        <div class="mt-1">
          California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to
          request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared
          personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a
          request, please submit your request in writing to us using the contact information provided below.
        </div>
        <div class="mt-1">
          If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the
          contact information provided below and include the email address associated with your account and a statement that you reside in
          California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be
          completely or comprehensively removed from all our systems (e.g., backups, etc.).
        </div>
        <div class="mt-3">CCPA Privacy Notice</div>
        <div class="mt-1 mb-3">The California Code of Regulations defines a "resident" as:</div>
        <ol>
          <li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
          <li>
            every individual who is domiciled in the State of California who is outside the State of California for a temporary or
            transitory purpose
          </li>
        </ol>
        <div class="mt-3">All other individuals are defined as "non-residents."</div>
        <div class="mt-1">
          If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal
          information.
        </div>
        <div class="f18 mt-4">What categories of personal information do we collect?</div>
        <div class="mt-1 mb-3">We have collected the following categories of personal information in the past twelve (12) months:</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tables" :key="item.category">
                <td>{{ item.category }}</td>
                <td>{{ item.examples }}</td>
                <td>{{ item.collected }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="my-3">
          We may also collect other personal information outside of these categories instances where you interact with us in person, online,
          or by phone or mail in the context of:
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Receiving help through our customer support channels;</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Participation in customer surveys or contests; and</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>Facilitation in the delivery of our Services and to respond to your inquiries.</div>
        </div>
        <div class="f18 mt-4">How do we use and share your personal information?</div>
        <div class="mt-1">More information about our data collection and sharing practices can be found in this privacy notice.</div>
        <div class="mt-1">
          You may contact us by email at <a href="mailto:info@snobar.app">info@snobar.app</a>, or by referring to the contact details at the
          bottom of this document.
        </div>
        <div class="mt-1">
          If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not
          submit proof that they have been validly authorized to act on your behalf.
        </div>
        <div class="f18 mt-4">Will your information be shared with anyone else?</div>
        <div class="mt-1">
          We may disclose your personal information with our service providers pursuant to a written contract between us and each service
          provider. Each service provider is a for-profit entity that processes the information on our behalf.
        </div>
        <div class="mt-1">
          We may use your personal information for our own business purposes, such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling" your personal information.
        </div>
        <div class="mt-1">
          SNOBAR has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding
          twelve (12) months. SNOBAR will not sell personal information in the future belonging to website visitors, users, and other
          consumers.
        </div>
        <div class="f18 mt-4">Your rights with respect to your personal data</div>
        <div class="mt-1 text-decoration-underline font-weight-medium">Right to request deletion of the data — Request to delete</div>
        <div class="mt-3">
          You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your
          request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the
          exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or
          any processing that may be required to protect against illegal activities.
        </div>
        <div class="mt-3 text-decoration-underline font-weight-medium">Right to be informed — Request to know</div>
        <div class="mt-1 mb-3">Depending on the circumstances, you have a right to know:</div>
        <div class="d-flex">
          <div class="square"></div>
          <div>whether we collect and use your personal information;</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>the categories of personal information that we collect;</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>the purposes for which the collected personal information is used;</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>whether we sell your personal information to third parties;</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>the categories of personal information that we sold or disclosed for a business purpose;</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>the business or commercial purpose for collecting or selling personal information.</div>
        </div>
        <div class="mt-3">
          In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in
          response to a consumer request or to re-identify individual data to verify a consumer request.
        </div>
        <div class="mt-3 text-decoration-underline font-weight-medium">
          Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights
        </div>
        <div class="mt-1">We will not discriminate against you if you exercise your privacy rights.</div>
        <div class="mt-3 text-decoration-underline font-weight-medium">Verification process</div>
        <div class="mt-1">
          Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the
          information in our system. These verification efforts require us to ask you to provide information so that we can match it with
          information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide
          certain information so that we can match the information you provide with the information we already have on file, or we may
          contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other
          verification methods as the circumstances dictate.
        </div>
        <div class="mt-1">
          We will only use personal information provided in your request to verify your identity or authority to make the request. To the
          extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot
          verify your identity from the information already maintained by us, we may request that you provide additional information for the
          purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided
          information as soon as we finish verifying you.
        </div>
        <div class="my-3 text-decoration-underline font-weight-medium">Other privacy rights</div>
        <div class="d-flex">
          <div class="square"></div>
          <div>You may object to the processing of your personal information.</div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of
            the information.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized
            agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
          </div>
        </div>
        <div class="d-flex">
          <div class="square"></div>
          <div>
            You may request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request,
            we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request
            submission.
          </div>
        </div>
        <div class="mt-3">
          To exercise these rights, you can contact us by email at info@snobar.app, or by referring to the contact details at the bottom of
          this document. If you have a complaint about how we handle your data, we would like to hear from you.
        </div>
      </div>
      <v-card id="9" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(9)">
        <div class="arrow">
          <img :class="section.some((i) => i == 9) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Do we make updates to this Notice?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 9)">
        <div class="mt-1">In Short: Yes, we update this place as necessary to stay compliant with relevant laws.</div>
        <div class="mt-1">
          We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the
          updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify
          you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review
          this privacy notice frequently to be informed of how we are protecting your information.
        </div>
      </div>
      <v-card id="10" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(10)">
        <div class="arrow">
          <img :class="section.some((i) => i == 10) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">How can you contact us about this Notice?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 10)">
        <div class="mt-1">If you have questions or comments about this notice, you may email us at info@snobar.app or by post to:</div>
        <div class="mt-1">SNOBAR</div>
        <div>Al Khaleej road Dammam , Eastern province 32242</div>
        <div>Saudi Arabia</div>
      </div>
      <v-card id="11" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(11)">
        <div class="arrow">
          <img :class="section.some((i) => i == 11) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">How can you review, update, or delete the data we collect from you?</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 11)" style="border-bottom: 1px solid #e0e0e0">
        <div class="mt-1">
          Based on the applicable laws of your country. you may have the right to request access to the personal information we collect from
          you, change that information, or delete it in some circumstances.
        </div>
      </div>
    </div>
    <div v-if="scrollPage >= 650" class="arrow-start link" @click="scrollToTop">
      <img src="@/assets/icon/right.svg" alt="arrow-right" />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      scrollPage: 0,
      section: [],
      menuList: [
        'What information do we collect?',
        'How do we process your information?',
        'What legal bases do we rely on to process your Personal Information?',
        'When and with whom do we share your personal information?',
        'How do we handle your Social logins?',
        'How long do we keep your information?',
        'What are your Privacy rights?',
        'Controls for do-not-track features',
        'Do California residents have specific Privacy rights?',
        'Do we make updates to this Notice?',
        'How can you contact us about this Notice?',
        'How can you review, update, or delete the data we collect from you?',
      ],
      tables: [
        {
          category: 'A.Identifiers',
          examples: `Contact details, such as real name, alias. postal address. telephone or mobile contact number, unique personal
            identifier, online identifier, Internet Protocol address, email address, and account name`,
          collected: 'NO',
        },
        {
          category: 'B.Personal information categories listed in the California Customer Records statute',
          examples: 'Name, contact information, education, employment, employment history, and financial information',
          collected: 'YES',
        },
        {
          category: 'C.Protected classification characteristics under California or federal law',
          examples: 'Gender and date of birth',
          collected: 'NO',
        },
        {
          category: 'D.Commercial information',
          examples: 'Transaction information, purchase history, financial details, and payment information',
          collected: 'NO',
        },
        {
          category: 'E.Biometric information',
          examples: 'Fingerprints and voiceprints',
          collected: 'NO',
        },
        {
          category: 'F.Internet or other similar network activity',
          examples: `Browsing history, search history, online behavior, interest data, and interactions with our and other websites,
            applications, systems, and advertisements`,
          collected: 'NO',
        },
        {
          category: 'G.Geolocation data',
          examples: 'Device location',
          collected: 'NO',
        },
        {
          category: 'H.Audio, electronic, visual, thermal, olfactory, or similar information',
          examples: 'Images and audio, video or call recordings created in connection with our business activities',
          collected: 'NO',
        },
        {
          category: 'I.Professional or employment-related information',
          examples: `Business contact details in order to provide you our services at a business level or job title, work history, and
            professional qualifications if you apply for a job with us`,
          collected: 'NO',
        },
        {
          category: 'J.Education Information',
          examples: 'Student records and directory information',
          collected: 'NO',
        },
        {
          category: 'K. Inferences drawn from other personal information',
          examples: `Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for
            example, an individual\'s preferences and characteristics`,
          collected: 'NO',
        },
      ],
    };
  },
  methods: {
    scrollToItem(id) {
      this.openSection(id);
      this.$nextTick(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    },
    scrollToTop() {
      document.getElementById('start').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    openSection(s) {
      if (this.section.some((i) => i == s)) {
        const idx = this.section.indexOf(+s);
        this.section.splice(idx, 1);
      } else {
        this.section.push(+s);
      }
    },
    handleScroll() {
      this.scrollPage = window.pageYOffset;
    },
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  computed: {
    select() {
      return this.$store.getters.langValue;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style lang="scss">
.privacy {
  .sidebar {
    position: absolute;
    width: 350px;
    left: 50%;
    top: 72px;
    transform: translate(calc(-50% - 525px), 0);
    &-item {
      display: flex;
      align-items: flex-start;
      padding: 14px 8px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .app {
    border-radius: 10px !important;
    border-color: #e0e0e0 !important;
  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50% !important;
    background: #f1f5fa;
  }
  .arrow-bottom {
    transform: rotate(90deg);
  }
  .mn1 {
    margin-top: -1px;
  }
  .content {
    max-width: 700px;
    width: 100%;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    & > div:not(:nth-of-type(2)) {
      padding: 0 30px;
    }
    .square {
      margin: 10px 8px 0;
      min-width: 6px;
      height: 6px;
      background: var(--v-primary-base);
    }
    table {
      border-collapse: collapse !important;
    }
    th,
    td {
      font-size: 16px !important;
      color: var(--v-primary-base) !important;
      border: thin solid rgba(0, 0, 0, 0.12) !important;
    }
  }
  @media screen and (max-width: 1399px) {
    .sidebar {
      width: 250px;
      transform: translate(calc(-50% - 475px), 0);
    }
  }
  @media screen and (max-width: 1200px) {
    .sidebar {
      width: 100%;
      position: initial;
      transform: initial;
      .app {
        display: none;
      }
    }
  }
  @media screen and (max-width: 959px) {
    .content {
      margin: 0 auto;
      & > div:not(:nth-of-type(2)) {
        padding: 0 24px;
      }
    }
    // .sidebar {
    //   display: none;
    // }
  }
  @media screen and (max-width: 599px) {
    .content {
      & > div:not(:nth-of-type(2)) {
        padding: 0 16px;
      }
    }
  }
}
// .v-application--is-rtl {
//   .privacy {
//     .sidebar {
//       right: 50%;
//       left: initial;
//       transform: translate(calc(50% + 525px), 0);
//     }
//   }
// }
// @media screen and (max-width: 1399px) {
//   .v-application--is-rtl {
//     .privacy {
//       .sidebar {
//         transform: translate(calc(50% + 475px), 0);
//       }
//     }
//   }
// }
// @media screen and (max-width: 1200px) {
//   .v-application--is-rtl {
//     .privacy {
//       .sidebar {
//         width: 100%;
//         position: initial;
//         transform: initial;
//         .app {
//           display: none;
//         }
//       }
//     }
//   }
// }
</style>
